.starrating {
    display: flex;
    align-items: center;
}

.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
    margin: auto;
/*    display: inline-flex;*/
}
.rate:not(:checked) > input {
/*    position:absolute;*/
/*    top:-9999px;*/
/*    appearance: none;*/
    display: none;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
   color: #c59b08;
}

label {
/*    padding: 0;*/
    margin: 0;
    font-size: 1.1em;
/*    vertical-align: middle;*/
}