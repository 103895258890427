li ul {
    margin-bottom: 0;
}


table {
    margin-bottom: 1em;
}

@media (max-width: 684px) {
    nav ul {
/*        display: table;*/
        margin: 0 auto;
    }
    nav li {
        float: none;
    }
}

img {
    margin: 0 auto;
    float: none;
    display: block;
    max-width: 70%;
    min-width: 50%;
    max-height: 90%;
}

input {
/*    color: #be0000; darkish red*/
    color: #1d7484; 
/*    background-color: #f9f9f9;*/
/*    border-color: black;*/
/*    border-width: 3px;*/
}

input:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    border-width: 2px;
}

.home {
    padding: 5rem;
    overflow-wrap: anywhere;
}

@media (max-width: 684px) {
    .home {
        padding: 5rem 5%;
/*        font-size: 0.6rem;*/
    }
}

.center {
    text-align: center;
}

.tcenter td {
    text-align: center;
}

.centerTable {
    margin-left: auto;
    margin-right: auto;
}

/* .small  */
input {
    width: 3rem;
    margin: 0 0.5em 0 0.5em;
    height: 1.5em;
    padding: 0.5em;
    max-width: 10vw;
}

table input {
    margin: 0;
}

textarea {
/*    rows: 5;*/
    height: 10em;
}

.large {
    width: 80%;
    display: block;
}

.med {
    width: 28rem;
    max-width: 20vw;
}

.sm {
    width: 14rem;
    max-width: 15vw;
}

.ti {
    width: 7rem;
    max-width: 10vw;
}

.table {
    width: 8rem;
    margin: 0;
}

.probButton {
    padding: 0.1em 0.3em 0.1em 0.3em;
    margin: 0.2rem;
}
.probButton:hover {
    border: none;
    background-color: #982c61;
    color: #f9f9f9;
}

.linkbutton {
/*    display: inline-block;*/
position: absolute;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background-color: #1d7484;
    color: #f9f9f9;
    border-radius: 1px;
    border: 1px solid #1d7484;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;
    margin-bottom: 2rem;
}
.linkbutton:hover {
    background-color: #982c61;
    border-color: #982c61;
    color: #f9f9f9;
    outline: 0;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
    border: none;
}

.linkbutton.float-right {
    right: 6em;
}

.right {
    float: right;
    text-align: right;
}

.center {
    text-align: center;
    margin: auto;
}

.solutionbox {
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 2rem;
    border: 4px solid #1d7484;
}


/* colors!!!!! */

.solText, .st, .red {color: #ff1d58;}
.orange {color: #ef7e56;}
.yellow {color: #fede00;}
.green {color: #bada55;}
.blue {color: #3399ff;}
.purple {color: #a393eb;}
.black {color: #000;}
.bcblack {border-color: black;}


/* colors end.  */


.none {
    display: none;
}

.lmargin3 {
    margin-left: 3em;
}

.rmargin3 {
    margin-right: 3em;
}

.lmargin2 {
    margin-left: 2rem;
}

.rmargin2 {
    margin-right: 2rem;
}

.textAlignRight {
    text-align: right;
}

.dashed > ul {
    list-style-type: none;
}

.dashed > ul > li {
    text-indent: -5px;
}

.dashed > ul > li:before {
    content: "− ";
    text-indent: -10px;
}




.languageBar {
    margin: auto;
/*    margin-left: 2rem;*/
    margin-bottom: 4em;
    margin-top: 1em;
    width: 100%;
    display: inline-block;
}

.languageBar button {
    margin-left: 1em;
/*    min-width: 2rem;*/
    padding: 8px 10px;
}


.float-left {
    float: left;
}

.float-right {
    float: right;
}

.display-block {
    display: block;
}

.display-inline-block {
    display: inline-block;
}

.searchbar {
    width: 100%;
    max-width: 100%;
    height: 2rem;
    border-bottom: 2px solid;
    border-radius: 0;

/*    search icon */

    background-image: url("../public/search.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 1.5em;
    padding-left: 2rem;
}


.searchToggle {
    display: inline;
/*    width: 0.8em;*/
    margin-top: 0.5em;
    margin-left: 1em;
}

.searchResultsText {
    display: inline;
    float: right;
    margin-top:0.7em;
}

.inline-block {
    display: inline-block;
    width: 60%;
}


.border {
    border-style: solid;
    border-width: 2px;
    border-color: #747474;
    margin: auto;
    margin-bottom: 2.5rem;
    text-align: center;
    padding: 1em;
}

.padding-none {
    padding: 0;
}

.tpn td {
    padding: 0;
}

.margin-none {
    margin: 0;
}

.margin-bottom-none {
    margin-bottom: 0;
}

.margin-bottom-2, .mb2 {margin-bottom: 2rem;}
.mb0 {margin-bottom: 0rem;}

.m2n2 {margin-top: -2em;}

/* number table rows */
/* https://stackoverflow.com/questions/17012421/auto-number-table-rows */

table {
    counter-reset: rowNumber;
    display: table;
    margin-left: 2rem;
/*    margin-right: 2rem;*/
}

th, td {
/*    max-width: 5em;*/
/*    min-width: fit-content;*/
/*    white-space: nowrap;*/
    min-width: 1.5em;
}



.cs6 {counter-set: rowNumber 5;}
.cs9 {counter-set: rowNumber 8;}
.cs10 {counter-set: rowNumber 9;}
.cs13 {counter-set: rowNumber 12;}
.cs14 {counter-set: rowNumber 13;}
.cs16 {counter-set: rowNumber 15;}
.cs17 {counter-set: rowNumber 16;}
.cs18 {counter-set: rowNumber 17;}
.cs19 {counter-set: rowNumber 18;}
.cs21 {counter-set: rowNumber 20;}
.cs24 {counter-set: rowNumber 23;}
.cs28 {counter-set: rowNumber 27;}
.cs33 {counter-set: rowNumber 32;}

.bg-grey {
    background-color: grey;
}

th {
    padding-left: 0;
}

table tr > td:first-child:nth-last-child(2), table tr > td:nth-child(2):nth-last-child(1) {
/*    text-indent: -1rem;*/
    padding-left: 0;
    padding-right: 2rem;

}

table tr > td:first-child {
    counter-increment: rowNumber;
}

table tr td:first-child:nth-last-child(2)::before {
    content: counter(rowNumber);
    min-width: 1em;
    margin-right: 0.5em;
    font-size: 90%;
    color: #a9a9a9;
}

table td:nth-child(2):nth-last-child(1)::before {
    content: counter(rowNumber, upper-alpha);
    width: 1em;
    font-size: 90%;
    color: #a9a9a9;
    display: inline-block;
}


.no2Count table td:nth-child(2):nth-last-child(1)::before, table.no2Count td:nth-child(2):nth-last-child(1)::before {
    content: none;
    width: 0;
}

.no2Count table td:nth-child(2):nth-last-child(1), table.no2Count td:nth-child(2):nth-last-child(1) {
    text-indent: 0;
/*    padding-left: 0.7em;*/
}

.no1Count table td:first-child:nth-last-child(2)::before, table.no1Count td:first-child:nth-last-child(2)::before {
    content: none;
    width: 0;
}



.lh1 {line-height: 1em;}

.width-30 {max-width: 30%;}
.width-40 {max-width: 40%;}
.width-50 {max-width: 50%;}
.width-80 {max-width: 80%;}
.width-200 {max-width: 200px;}
.width-400 {max-width: 400px;}
.width-500 {max-width: 500px;}
.width-600 {max-width: 600px;}
.width-800 {max-width: 800px;}
.width-1000 {max-width: 1000px;}
.width-1200 {max-width: 1200px;}
.width-1500 {max-width: 1500px;}
.width-fit {width: fit-content;}
.width2-600 {max-width: 600px;}


.border-hidden, .border-hidden tr {
    border: hidden;
}

.border-all td {
    border: black solid 1px;
}

.padding-none td {
    padding: 0 0 0 0;
}

.padding-fourth td, .pf td {
    padding: 0.25em;
}

.padding-left td {
    padding-left: 1em;
}

.pvf td {padding: 0.25em 0}
/* column 3 margin-left 2 */
.c3ml2 td:nth-child(3), .c3ml2 th:nth-child(3) {padding-left: 2rem;}
.c3ml4 td:nth-child(3), .c3ml4 th:nth-child(3) {padding-left: 4rem;}
.c4w5  td:nth-child(4), .c4w5  td:nth-child(4) {min-width: 200px; }

/* column 1 BIG */
.c1big td:first-child {font-size: 2rem;}

.bigscript {font-size: 2rem;}

/* typewriter effect */
/* reference: https://www.sitepoint.com/css-typewriter-effect/ */
/* https://css-tricks.com/snippets/css/typewriter-effect/ */

.typed-out-container {
    display: inline-block;
}

.typed-out {
    overflow: hidden;
    border-right: .15em solid orange;
    white-space: nowrap;
    width: 0;
    animation:  typing 2s steps(30) forwards, 
                blink 800ms steps(44) infinite normal;

}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink {
  from { border-color: orange }
  to { border-color: transparent; }
}





/* CANVAS - fabric js */

.canvas-container {
    position: absolute !important;
    z-index: 5;
    top: 2rem;
    left: 0;
}

a, button {
    z-index: 6;
}

.left {text-align: left;}




/* completion, rating etc */

.rateBar {
/*    align-items: center;*/
    margin: 0 auto;
    display: flex;
    width: fit-content;
    flex-wrap: wrap;
    justify-content: center;
}

.completed-button {
/*    margin: 0 auto;*/
/*    display: block;*/
    border-radius: 5px;
    border: none;
}


.red-button {background-color: #e74b47;}
.red-button:hover {background-color: #ef8684 !important;}
.blue-button {background-color: #1e88e5;}
.blue-button:hover {background-color: #68afee !important;}



.break {
    flex-basis: 100%;
}