body {
  margin: 0;
/*  font-size: 50%;*/
  font-size: 62.5%;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  max-width: 100%;
  overflow-x: hidden;
  height: auto !important;
}

/* below is from sakura */
/* https://github.com/oxalorg/sakura/blob/master/css/sakura.css  */

body {
  font-size: 20px;
  line-height: 1.518;
  margin: auto;
  color: #4a4a4a;
  background-color: #f9f9f9;
  padding: 13px;
}

@media (max-width: 1080px) {
  body {
    font-size: 0.8rem;
  }
}

@media (max-width: 684px) {
  body {
    font-size: 0.6rem;
  }
}
@media (max-width: 382px) {
  body {
    font-size: 0.6rem;
  }
}

h1 {
  font-size: 2.35em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.75em;
}

h4 {
  font-size: 1.5em;
}

h5 {
  font-size: 1.25em;
}

h6 {
  font-size: 1em;
}

p {
/*  margin-top: 0px;*/
/*  margin-bottom: 2.5rem;*/
}

small, sub, sup {
  font-size: 75%;
}

hr {
  border-color: #1d7484;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #1d7484;
}
a:visited {
  color: #1d78484;
}
a:hover {
  color: #982c61;
  border-bottom: 2px solid #4a4a4a;
}

ul {
  padding-left: 1.4em;
  margin-top: 0px;
  margin-bottom: 2.5rem;
}

li {
  margin-bottom: 0.4em;
}

blockquote {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 1em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  padding-right: 0.8em;
  border-left: 5px solid #1d7484;
  margin-bottom: 2.5rem;
  background-color: #f1f1f1;
}

blockquote p {
  margin-bottom: 0;
}

img, video {
  height: auto;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 2.5rem;
}

/* Pre and Code */
pre {
  background-color: #f1f1f1;
  display: block;
  padding: 1em;
  overflow-x: auto;
  margin-top: 0px;
  margin-bottom: 2.5rem;
  font-size: 0.9em;
}

code, kbd, samp {
  font-size: 0.9em;
  padding: 0 0.5em;
  background-color: #f1f1f1;
  white-space: pre-wrap;
}

pre > code {
  padding: 0;
  background-color: transparent;
  white-space: pre;
  font-size: 1em;
}

/* Tables */
table {
  text-align: justify;
  width: 100%;
  border-collapse: collapse;
}

td, th {
  padding: 0.5em;
  border-bottom: 1px solid #f1f1f1;
}

/* Buttons, forms and input */
input, textarea {
  border: 1px solid #4a4a4a;
}
input:focus, textarea:focus {
  border: 1px solid #1d7484;
}

textarea {
  width: 100%;
}

.button, button, input[type=submit], input[type=reset], input[type=button] {
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background-color: #1d7484;
  color: #f9f9f9;
  border-radius: 1px;
  border: 1px solid #1d7484;
  cursor: pointer;
  box-sizing: border-box;
}
.button[disabled], button[disabled], input[type=submit][disabled], input[type=reset][disabled], input[type=button][disabled] {
  cursor: default;
  opacity: 0.5;
}
.button:focus:enabled, .button:hover:enabled, button:active:focus:enabled, button:hover:enabled, input[type=submit]:focus:enabled, input[type=submit]:hover:enabled, input[type=reset]:focus:enabled, input[type=reset]:hover:enabled, input[type=button]:focus:enabled, input[type=button]:hover:enabled {
  background-color: #982c61;
  border-color: #982c61;
  color: #f9f9f9;
  outline: 0;
}

textarea, select, input {
  color: #4a4a4a;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  margin-bottom: 10px;
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
}
textarea:focus, select:focus, input:focus {
  border: 1px solid #1d7484;
  outline: 0;
}

input[type=checkbox]:focus {
  outline: 1px dotted #1d7484;
}

label, legend, fieldset {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}


/* Reference: https://github.com/dhg/Skeleton/blob/master/css/skeleton.css  */

th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #E1E1E1; }
th:first-child,
td:first-child {
  padding-left: 0; }
th:last-child,
td:last-child {
  padding-right: 0; }


/* GRIDS, from skeleton.css (above) */

/* Grid
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.container {
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box; }
.column,
.columns {
  width: 100%;
  float: left;
  box-sizing: border-box; }

/* For devices larger than 400px */
@media (min-width: 400px) {
  .container {
    width: 85%;
    padding: 0; }
}

/* For devices larger than 550px */
@media (min-width: 550px) {
  .container {
    width: 80%; }
  .column,
  .columns {
    margin-left: 4%; }
  .column:first-child,
  .columns:first-child {
    margin-left: 0; }

  .one.column,
  .one.columns                    { width: 4.66666666667%; }
  .two.columns                    { width: 13.3333333333%; }
  .three.columns                  { width: 22%;            }
  .four.columns                   { width: 30.6666666667%; }
  .five.columns                   { width: 39.3333333333%; }
  .six.columns                    { width: 48%;            }
  .seven.columns                  { width: 56.6666666667%; }
  .eight.columns                  { width: 65.3333333333%; }
  .nine.columns                   { width: 74.0%;          }
  .ten.columns                    { width: 82.6666666667%; }
  .eleven.columns                 { width: 91.3333333333%; }
  .twelve.columns                 { width: 100%; margin-left: 0; }

  .one-third.column               { width: 30.6666666667%; }
  .two-thirds.column              { width: 65.3333333333%; }

  .one-half.column                { width: 48%; }

  /* Offsets */
  .offset-by-one.column,
  .offset-by-one.columns          { margin-left: 8.66666666667%; }
  .offset-by-two.column,
  .offset-by-two.columns          { margin-left: 17.3333333333%; }
  .offset-by-three.column,
  .offset-by-three.columns        { margin-left: 26%;            }
  .offset-by-four.column,
  .offset-by-four.columns         { margin-left: 34.6666666667%; }
  .offset-by-five.column,
  .offset-by-five.columns         { margin-left: 43.3333333333%; }
  .offset-by-six.column,
  .offset-by-six.columns          { margin-left: 52%;            }
  .offset-by-seven.column,
  .offset-by-seven.columns        { margin-left: 60.6666666667%; }
  .offset-by-eight.column,
  .offset-by-eight.columns        { margin-left: 69.3333333333%; }
  .offset-by-nine.column,
  .offset-by-nine.columns         { margin-left: 78.0%;          }
  .offset-by-ten.column,
  .offset-by-ten.columns          { margin-left: 86.6666666667%; }
  .offset-by-eleven.column,
  .offset-by-eleven.columns       { margin-left: 95.3333333333%; }

  .offset-by-one-third.column,
  .offset-by-one-third.columns    { margin-left: 34.6666666667%; }
  .offset-by-two-thirds.column,
  .offset-by-two-thirds.columns   { margin-left: 69.3333333333%; }

  .offset-by-one-half.column,
  .offset-by-one-half.columns     { margin-left: 52%; }

}